import React from 'react'
import styled from 'styled-components/macro'
import RushLoader from '../assets/images/logo-red.png'

// eslint-disable-next-line react/display-name
export const Loader = React.memo((props) => {
  return (
    <LoaderTag>
      <div className="overlay__inner">
        <div className="overlay__content">
          <span className="spinner"></span>
        </div>
      </div>
    </LoaderTag>
  )
})

const LoaderTag = styled.div`
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: #222222c2;
  z-index: 999999;

  .overlay__inner {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .overlay__content {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    background-image: url(${RushLoader});
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto;
  }

  .spinner {
    width: 150px;
    height: 150px;
    display: inline-block;
    border-width: 8px;
    border-color: #fff;
    border-top-color: #d91f26;
    animation: spin 1s infinite linear;
    border-radius: 100%;
    border-style: solid;
  }

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`
