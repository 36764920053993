import React from 'react'
import { createBrowserRouter } from 'react-router-dom'

const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))

export const router = createBrowserRouter([
  {
    path: '/login',
    Component: Login,
    errorElement: Page500,
  },
  {
    path: '*',
    Component: DefaultLayout,
    errorElement: Page500,
    children: [
      {
        index: true,
        Component: Dashboard,
      },
    ],
  },
])

export default router
