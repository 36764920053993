import { configureStore, isRejectedWithValue } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { createInjectorsEnhancer } from 'redux-injectors'
import createSagaMiddleware from 'redux-saga'
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { toast } from 'react-toastify'
import { createReducer } from './reducers'
import { api } from './services/api'
import auth, { logout } from './slice/authSlice'
import app from './slice/appSlice'

const rtkQueryErrorLogger = (api) => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  if (isRejectedWithValue(action)) {
    const { payload = {} } = action
    const { data = { code: 0, message: 'Please check internet connection' } } = payload
    const { code, message } = data
    if (code === 401 && message === 'Expired JWT Token') {
      toast.error('Session expired, please login again!')
      return next({
        type: logout.type,
      })
    } else if (code === 0) {
      toast.error(message)
    }
  }

  return next(action)
}

export function configureAppStore() {
  const persistConfig = {
    key: 'rush-2-cms',
    version: 1,
    blacklist: [api.reducerPath],
    storage,
  }
  const reduxSagaMonitorOptions = {}
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions)
  const { run: runSaga } = sagaMiddleware

  // Create the store with saga middleware
  const middleWares = [sagaMiddleware, rtkQueryErrorLogger]

  const enhancers = [
    createInjectorsEnhancer({
      createReducer,
      runSaga,
    }),
  ]

  if (process.env.NODE_ENV === `development`) {
    const { logger } = require(`redux-logger`)
    middleWares.push(logger)
  }
  const persistedReducer = persistReducer(
    persistConfig,
    createReducer({
      [api.reducerPath]: api.reducer,
      auth,
      app,
    }),
  )
  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      })
        .concat(middleWares)
        .concat(api.middleware),
    devTools: process.env.NODE_ENV !== 'production',
    enhancers,
  })

  setupListeners(store.dispatch)

  return store
}
