import React, { Suspense } from 'react'
import './scss/style.scss'
import { Loader } from './components/Loader'
import router from './router'
import { RouterProvider } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export function App() {
  return (
    <Suspense fallback={<Loader />}>
      <RouterProvider router={router} fallbackElement={<Loader />} />
      <ToastContainer position="top-center" />
    </Suspense>
  )
}

export default App
