import { api } from './api'

export const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation({
      query: (credentials) => ({
        url: 'auth',
        method: 'POST',
        body: credentials,
      }),
      extraOptions: {
        maxRetries: 0,
      },
    }),
    getErrorProne: build.query({
      query: () => 'error-prone',
    }),
  }),
})

export const { useLoginMutation, useGetErrorProneQuery } = authApi

export const {
  endpoints: { login },
} = authApi
