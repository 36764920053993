/*
 * Copyright © 2023 Kuldip Pipaliya <kuldipem@gmail.com>. All rights reserved.
 *
 * Permission is hereby granted, free of charge, to any person obtaining a copy of this software and associated documentation files (the "Software"), to deal in the Software without restriction, including without limitation the rights to use, copy, modify, merge, publish, distribute, sublicense, and/or sell copies of the Software, and to permit persons to whom the Software is furnished to do so, subject to the following conditions:
 *
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import { format, parseJSON } from 'date-fns'

function createKeyValueObject(obj) {
  return Object.keys(obj).map((key) => {
    return {
      value: obj[key],
      label: key,
    }
  })
}

export const transformSettings = (data) => {
  return {
    hearAboutUs: createKeyValueObject(data.hearAboutUs),
    provinces: createKeyValueObject(data.province),
    partners: data.partners,
    countryCodes: data.countryCodes,
    subscriptions: data.subscription,
    documents: data.document,
    services: data.services.map(({ name }) => ({
      name,
    })),
  }
}

export const processPostDomesticQuickQuote = (
  state,
  action,
  isLoading,
  hasError = null,
  rates = [],
) => {
  const {
    meta: { arg },
  } = action
  const { originalArgs } = arg
  const { partner } = originalArgs
  const error = hasError
    ? `Something went wrong, Unable to fetch rates from our partner (${partner}) at the moment`
    : null
  state.domesticQuoteBag = {
    ...state.domesticQuoteBag,
    [partner]: { rates, isLoading, error },
  }
}

export const processPostInternationalQuickQuote = (
  state,
  action,
  isLoading,
  hasError = null,
  rate = null,
) => {
  const {
    meta: { arg },
  } = action
  const { originalArgs } = arg
  const { partner } = originalArgs
  const error = hasError
    ? `Something went wrong, Unable to fetch rates from our partner (${partner}) at the moment`
    : null
  state.internationalQuoteBag = {
    ...state.internationalQuoteBag,
    [partner]: { rate, isLoading, error },
  }
}

export const parseIdFromUrl = (url) => {
  const urlParts = url.split('/')
  return urlParts[urlParts.length - 1]
}

export const formatCurrency = (value = 0) => {
  if (typeof value === 'string') {
    value = parseFloat(value)
  }
  return `${value.toLocaleString('en-ZA', {
    style: 'currency',
    currency: 'ZAR',
  })}`
}

export const currency = (value) => {
  return formatCurrency(value)
}

export const transformAddressToString = (address) => {
  const fields = [
    address.addressLineOne,
    address.addressLineTwo,
    address.suburb,
    address.city,
    address.postcode,
  ]
  return fields.filter((field) => field !== '').join(', ')
}

export const toDate = (dateStr) => {
  return dateStr ? format(parseJSON(dateStr), 'dd-MM-yyyy') : '-'
}

export const toDateTime = (dateStr) => {
  return dateStr ? format(parseJSON(dateStr), 'dd-MM-yyyy hh:mm a') : '-'
}

export const toDateTime24 = (dateStr) => {
  return dateStr ? format(parseJSON(dateStr), 'dd-MM-yyyy HH:mm') : '-'
}

export const parseFileName = (headers) => {
  return headers
    .get('content-disposition')
    .split(';')
    .find((n) => n.includes('filename='))
    .replace('filename=', '')
    .trim()
}

export const saveData = (function () {
  var a = document.createElement('a')
  document.body.appendChild(a)
  a.style = 'display: none'
  return function (data, fileName) {
    const url = window.URL.createObjectURL(data)
    a.href = url
    a.download = fileName
    a.click()
    window.URL.revokeObjectURL(url)
  }
})()

export const REPORT_TYPES = [
  'debtors',
  'international-quick-quotes',
  'invoices',
  'tracking-orders',
  'orders',
  'payments',
  'wallet-users',
  'users',
  'wallet-transactions',
]
