import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import { configureAppStore } from './store'
import { HelmetProvider } from 'react-helmet-async'
import { RecoilRoot } from 'recoil'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import { Loader } from './components/Loader'

async function render() {
  const root = ReactDOM.createRoot(document.getElementById('root'))
  const store = configureAppStore()
  const persistor = persistStore(store)

  root.render(
    <Provider store={store}>
      <PersistGate loading={<Loader />} persistor={persistor}>
        <HelmetProvider>
          <React.StrictMode>
            <RecoilRoot>
              <App />
            </RecoilRoot>
          </React.StrictMode>
        </HelmetProvider>
      </PersistGate>
    </Provider>,
  )

  reportWebVitals()
}

render()
